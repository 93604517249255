import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd';
import './product-button.scss';

interface IProps extends ButtonProps {
    typeBtn?: 'primary' | 'secondary';
}

const ProductButton = ({
    children,
    className,
    typeBtn = 'primary',
    icon,
    onClick,
    htmlType,
    loading,
    disabled
}: IProps) => {
    let customClassName = '';
    switch (typeBtn) {
        case 'secondary':
            customClassName = 'secondary-btn';
            break;
        case 'primary':
            customClassName = 'primary-btn';
            break;
        default:
            customClassName = 'primary-btn';
            break;
    }

    return (
        <Button
            className={`btn ${customClassName} ${className}`}
            icon={icon}
            onClick={onClick}
            htmlType={htmlType}
            loading={loading}
            disabled={disabled}>
            {children}
        </Button>
    );
};

export default ProductButton;
